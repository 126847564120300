import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.leads,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          autoComplete: "off",
          ref: "refLeadsForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "姓名",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.leads.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.leads.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "电话",
              name: "mobile"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.leads.mobile,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.leads.mobile) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "性别" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.leads.gender,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.leads.gender) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: "Male" }, {
                      default: _withCtx(() => [
                        _createTextVNode("男")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: "Female" }, {
                      default: _withCtx(() => [
                        _createTextVNode("女")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "来源",
              name: "src"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  options: _ctx.data.sourceType,
                  value: _ctx.leads.src,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.leads.src) = $event)),
                  placeholder: "来源",
                  "field-names": { label: 'name', value: 'id', options: 'children' }
                }, null, 8, ["options", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "标签",
              name: "tagIds"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  options: _ctx.data.tagList,
                  mode: "multiple",
                  value: _ctx.leads.tagIds,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.leads.tagIds) = $event)),
                  placeholder: "标签",
                  "field-names": { label: 'name', value: 'id', options: 'children' }
                }, null, 8, ["options", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "备注",
              name: "comment"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.leads.comment,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.leads.comment) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    })
  ]))
}