
import { Rule } from "ant-design-vue/lib/form";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  UnwrapRef,
} from "vue";
import STATUS from "@/components/model/enum";
import { Leads } from "@/api/model/leads";
import { updateLeads, createLeads, getLeads } from "@/api/leads";
import { isMobile } from "@/utils/validate";
import { dictList } from "@/api/user";


export default defineComponent({
  name: "LeadsForm",
  emits: ["onCancel"],
  props: ["id", "type"],

  setup(props, context) {
    const data = reactive<{
      loading: boolean,
      sourceType: any[],
      tagList: any[]
    }>({
      loading: false,
      sourceType: [],
      tagList: []
    });
    let leads: UnwrapRef<Leads> = reactive({
      id: "",
      name: "",
      comment: "",
      mobile: "",
      gender: "Male",
      percentage: "",
      available: "",
      src: '',
      tagIds: []
    });

    const setFormValue = async (id: any) => {
      const res = await getLeads(id);
      for (const key in leads) {
        leads[key as keyof typeof leads] = res[key];
        leads.tagIds = leads.tagIds?leads.tagIds:[]
      }
    }

    const rules: Record<string, Rule[]> = {
      name: [
        {
          required: true,
          trigger: "change",
          validator: (rule, value) => {
            if (!value) {
              return Promise.reject("请输入姓名!");
            }
            return Promise.resolve();
          },
        },
      ],
      mobile: [
        {
          required: true,
          trigger: "change",
          validator: (rule, value) => {
            if (value == '') {
              return Promise.reject("请输入手机号!");
            }
            if (!isMobile(value)) {
              return Promise.reject("手机号格式错误!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      src: [
        {
          required: true,
          message: '请选择来源',
          trigger: "change",
        },
      ],
      tagIds: [
        {
          required: true,
          message: '请选择标签',
          trigger: "change",
        },
      ]
    };


    const refLeadsForm = ref()
    const onSubmit = async () => {
      refLeadsForm.value.validateFields().then(() => {
        if (props.type == 1) {
          update(leads)
        } else {
          add(leads)
        }
      })
    };

    const add = (option: any) => {
      createLeads(option)
        .then(() => {
          context.emit("onCancel");
        })
        .catch(() => {
          context.emit("onCancel");
        });
    }
    const update = (option: any) => {
      updateLeads(option).then(() => {
        context.emit("onCancel");
      }).catch(() => {
        context.emit("onCancel");
      });
    }

    const searchDictList = () => {
      dictList({
        "dictCodes": []
      }).then((res: any[]) => {
        console.log(res);
        data.sourceType = []
        data.tagList = []
        res.forEach(element => {
          if (element.dictCode == 'CUSTOMER_TYPE') {
            data.sourceType.push(element)
          } else if (element.dictCode == 'CUSTOMER_TAG') {
            data.tagList.push(element)
          }
        });
      });
    }

    onMounted(async () => {
      searchDictList();
      if (props.id === 0) return;
      await setFormValue(props.id)
    });
    return {
      ...toRefs(data),
      ...toRefs(leads),
      labelCol: { style: { width: "150px" } },
      wrapperCol: { span: 14 },
      refLeadsForm,
      leads,
      rules,
      data,
      onSubmit,
      STATUS,
    };
  },
  components: {},
});
